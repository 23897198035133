import React, {Component} from 'react';

import HeaderHomePage from '../Components/HeaderHomePage';

import Footer from '../Components/Footer';

import {
    Container,
    Button,
    useAccordionToggle,
    Accordion,
    Tab,
    Nav,
    Image,
    Row,
    Col,
    CardGroup,
    Card
} from 'react-bootstrap';

import {URL} from '../constants';
import {Link} from 'react-router-dom';

function CustomToggle({children, eventKey}) {
    const decoratedOnClick = useAccordionToggle(eventKey, (e) => {
        console.log(e);
    });

    return (
        <Card.Header onClick={decoratedOnClick}>

            {children} </Card.Header>
    );
}


export default class HomePage extends Component {


    constructor(props) {
        super(props);
        this.state = {
            services: []
        };
    }


    componentDidMount() {
        fetch(URL + '/api/services').then(response => {
            return response.json();
        }).then(websitelist => {
      
            this.setState({services: websitelist, loading: false});




            localStorage.setItem('services',JSON.stringify(websitelist));
        }).catch(error => console.error('Error:', error));
    }


    customServices() {

        var returnHtml = [];
        var html = [];
        var increment=0;
        if (this.state.services !== undefined) {
            this.state.services.map((service, index) => { // console.log(products);

                increment++;
                html.push (
                    <Card className="d-flex align-items-center" key={index}>
                        <Card.Img variant="top img-fluid"
                            src={
                                './img/' + service.icon
                            }/>
                        <Card.Body>
                            <Card.Title>{
                                service.name
                            }</Card.Title>
                            <Card.Text> {
                                service.short_descriptions
                            } </Card.Text>
                            <Link className="btn btn-success textMedium" to="/pricing">
                                LEARN MORE
                            </Link>
                        </Card.Body>
                    </Card>
                )
                if ((increment%3) ==0) {
                    returnHtml.push (
                        <CardGroup className="custom-card-home" key={index}>
                            {html} 
                        </CardGroup>
                    )
                    html =[];
                }else{

                }
            });

        }

        return returnHtml;
    }


    render() {

        return (
            <React.Fragment>
                <HeaderHomePage/>
                <Container fluid={true}
                    className="bg-gray py-5 HomePage" id="services">
                    <Container>
                        <Row className="justify-content-center">
                            <h3>
                                <span className="textGreen">OUR</span>
                                <span className="textBload textBlue">
                                    SERVICES
                                </span>
                            </h3>

                        </Row>
                        {/* <Row className="justify-content-center">
                            <Col lg="5">
                                <p className="textSize14 text-center">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>

                            </Col>
                        </Row> */}

                        <Row className="py-4">

                                {this.customServices()} 
                        </Row>
                    </Container>
                </Container>
                {/* <Container fluid={true} className="py-5">
                    <Container >
                        <Row className="centerContent">
                            <h3><span className="textGreen">WHAT IS </span> <span className="textBload textBlue"> WISEBET? </span></h3>
                        </Row>
                        <Row>
                            <Col lg="7">
                                <Row>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                </Row>
                                <Row>
                                    <ul className="homepageOklist">
                                        <li> <a href="#">Set your odds according to your analysis. </a></li>
                                        <li><a href="#">Update your offers odds following new informations about events.</a></li>
                                        <li><a href="#">Place offers on different outcomes to ensure gains.</a></li>
                                        <li><a href="#">Trade your bets to secure gains or cut losses.</a></li>
                                        <li><a href="#">Combine with bookmaker role to ensure gains.</a></li>
                                    </ul>
                                </Row>
                                <Row className="centerContent">
                                    <Image className="d-block d-sm-none" src="./img/football.webp" fluid />
                                    <Button variant="success" size="md">
                                        GET STARTED
                                    </Button>
                                </Row>
                            </Col>
                            <Col lg="5 d-none d-sm-block">
                                <Image src="./img/football.webp" fluid />
                            </Col>
                        </Row>
                    </Container>
                </Container> */}

                <Container fluid={true}
                    className=" py-5">
                    <Container>
                        <Row className="justify-content-center">
                            <h3>
                                <span className="textGreen">HOW</span>
                                <span className="textBload textBlue">
                                    WE WORK ?
                                </span>
                            </h3>

                        </Row>
                        <Row className="justify-content-center">
                            <Col lg="5">
                                <p className="textSize14 text-center">
                                    We will work free until your satisfaction .
                                </p>
                            </Col>
                        </Row>

                        <Row className="py-4">
                            <CardGroup className="custom-card-home2">
                                <Card className="d-flex align-items-center">
                                    <h4>01</h4>
                                    <Card.Img src="./img/createaccount1.webp"/>
                                    <Card.Body>
                                        <Card.Title>SEND YOUR PHOTO</Card.Title>
                                        <Card.Text>
                                            After recive your photos , we will send to production for editting
                                        </Card.Text>
                                    </Card.Body>
                                    <Col lg="3 arrow">
                                        <Image src="./img/createaccountarrow.webp"/>

                                    </Col>

                                </Card>
                                <Card className="d-flex align-items-center">
                                    <h4>02</h4>
                                    <Card.Img src="./img/deposit2.webp"/>
                                    <Card.Body>
                                        <Card.Title>CHECKING</Card.Title>
                                        <Card.Text>
                                            You can check our quality and if anything missing we wil do again
                                        </Card.Text>
                                    </Card.Body>
                                    <Col lg="3 arrow">
                                        <Image src="./img/depositarrow.webp"/>

                                    </Col>

                                </Card>
                                <Card className="d-flex align-items-center">
                                    <h4>03</h4>

                                    <Card.Img src="./img/play3.webp"/>


                                    <Card.Body>
                                        <Card.Title>PAYMENT</Card.Title>
                                        <Card.Text>
                                            After checking everything you should pay us and we will send you final delivery
                                        </Card.Text>
                                    </Card.Body>

                                </Card>
                            </CardGroup>

                        </Row>
                    </Container>
                </Container>
                <Container fluid={true}
                    className=" bg-gray py-5" >
                    <Container className="custom-tab">
                        <Row>
                            <h3 className="py-5 cuh3">
                                <span className="textGreen">
                                    FREQUENTLY
                                </span>
                                <span className="textBload textBlue">
                                    ASKED QUESTIONS
                                </span>
                            </h3>
                        </Row>

                        <Tab.Container id="left-tabs-home" defaultActiveKey="first">
                            <Row>
                                <Col lg="4 pl-0 pr-0">
                                    <Col lg="9 pl-0 pr-0">

                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first">General Questions</Nav.Link>

                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second">Account Questions</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third">Rules</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Image className="d-none d-sm-block" src="./img/buttonleftimg.webp" fluid/>

                                    </Col>

                                </Col>
                                <Col lg={8}
                                    className="custom-tab">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <Accordion defaultActiveKey="0">
                                                <Card>

                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>
                                                            Yes you can send us photo for free trial as per as service .
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                    <CustomToggle eventKey="0">Can i get free trial?</CustomToggle>
                                                </Card>
                                                <Card>

                                                    <Accordion.Collapse eventKey="1">
                                                        <Card.Body>
                                                            We have dedicated service team 24x7 . Sometimes its take maximum 24 hours.
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                    <CustomToggle eventKey="1">


                                                        How long after sending the picture can I get a free trial?

                                                    </CustomToggle>

                                                </Card>
                                                {/* <Card>

                                                    <Accordion.Collapse eventKey="2">
                                                        <Card.Body>Hello! I'm another body</Card.Body>
                                                    </Accordion.Collapse>
                                                    <CustomToggle eventKey="2">Is there a minimum amount to deposit? to withdraw?</CustomToggle>

                                                </Card>
                                                <Card>

                                                    <Accordion.Collapse eventKey="3">
                                                        <Card.Body>Hello! I'm another body</Card.Body>
                                                    </Accordion.Collapse>
                                                    <CustomToggle eventKey="3">What happens to postponed or canceled events?</CustomToggle>

                                                </Card> */} </Accordion>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Accordion defaultActiveKey="0">
                                                <Card>

                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>No at all . After place an order we will show you demo if you confirm everything fine after then we will request for payment.</Card.Body>
                                                    </Accordion.Collapse>
                                                    <CustomToggle eventKey="0">Is there a minimum amount to deposit? to withdraw?</CustomToggle>

                                                </Card>


                                            </Accordion>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <Accordion defaultActiveKey="0">
                                                <Card>

                                                    <Accordion.Collapse eventKey="0">
                                                        <Card.Body>Its depend on times how much photos we have completed .
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                    <CustomToggle eventKey="0">What happens to postponed or canceled events?</CustomToggle>

                                                </Card>

                                            </Accordion>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                        <Row>
                            <Image className="d-none d-sm-none" src="./img/buttonleftimg.webp" fluid/>
                        </Row>

                    </Container>
                </Container>


                <Footer/>
            </React.Fragment>


        )
    }


}
