import React, {Component} from 'react';
import {
    Card,
    Form,
    Table,
    InputGroup,
    FormControl,
    Row,
    Col,
    Container,
    Image,
    Button
} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import NavPage from '../Components/Nav';


import Footer from '../Components/Footer';

const images = {
    clippingpath: {
        before: require('../img/clippingpath/before.webp'),
        after: require('../img/clippingpath/after.webp')
    },
    imageMasking: {
        image1: require('../img/clippingpath/before.webp'),
        image2: require('../img/clippingpath/after.webp')
    }


};




export default class Pricing extends Component {


    state = {
        minPrice: 0,
        maxPrice: 0,
        valueMinPrice: 0,
        valueMaxPrice: 0,
        quentity: 1,
        valuePrice: 0

    }


    productUpdate = (e) => {


        if (e.target.value !== "0") {


            var valuePrice = JSON.parse(e.target.value);


            var newMinprice = valuePrice.min * this.state.quentity;
            var newMaxprice = valuePrice.max * this.state.quentity;

            this.setState({
                minPrice: newMinprice,
                maxPrice: newMaxprice,
                valueMinPrice: valuePrice.min,
                valueMaxPrice: valuePrice.max,
                title: valuePrice.title,
                before: valuePrice.before,
                after: valuePrice.after

            });
        }
    }

    quentityPrice = (e) => {

        this.setState({quentity: e.target.value});

        // var newprice = this.state.minPrice * this.state.quentity;

        this.setState({
            minPrice: this.state.valueMinPrice * this.state.quentity,
            maxPrice: this.state.valueMaxPrice * this.state.quentity
        });

    }


    pricingOptions = (e) => {

        var localServices = localStorage.services && JSON.parse(localStorage.getItem('services'));
        var html = [];
        if (localStorage.services) {


            localServices.map(service => {

                var tempData = {
                    "title": service.name,
                    "min": service.min,
                    "max": service.max,
                    "before": service.before_img,
                    "after": service.after_img
                };


                html.push (
                    <option value={
                        JSON.stringify(tempData)
                    }>
                        {
                        service.name
                    }</option>
                );
            })


        }

        return html;


    }


    render() {
        return (
            <React.Fragment>
                <Container fluid className="bg-gray">
                    <Row>
                        <Container>
                            <Row>
                                <NavPage/>
                            </Row>
                        </Container>

                    </Row>

                </Container>
                <Container>
                    <Row className="d-flex justify-content-center">
                        <h3 className="py-5 small-border-bottom">Image-editing Service Pricing</h3>
                    </Row>

                </Container>

                <Container className="mb-3">
                    <Row>


                        <Form>
                            <Row className="pricingcontent py-5">

                                <Col lg="6 px-5 border-right">
                                    <h4 className="text-info pb-3">Calculate your estimate</h4>

                                    <Form.Group className="py-2" controlId="exampleForm.ControlSelect1">
                                        <Form.Label>Select a service*</Form.Label>
                                        <Form.Control as="select"
                                            onChange={
                                                this.productUpdate
                                        }>

                                            <option value="0">Select a Service</option>
                                            {
                                            this.pricingOptions()
                                        }
                                            {/* <option value='{"title":"Clipping Path", "price":0.49}'>Clipping Path</option>
                                            <option value='{"title":"Multi-clipping Path", "price":1.9}'>Multi-clipping Path</option>
                                            <option value='{"title":"Image Masking", "price":1.93}'>Image Masking</option>
                                            <option value='{"title":"Background Removal", "price":0.49}'>Background Removal</option>
                                            <option value='{"title":"Ghost Mannequin", "price":0.99}'>Ghost Mannequin</option>
                                            <option value='{"title":"Drop Shadow", "price":0.25}'>Drop Shadow</option>
                                            <option value='{"title":"Photo Retouching", "price":0.79}'>Photo Retouching</option>
                                            <option value='{"title":"Color Variants", "price":0.99}'>Color Variants</option>
                                            <option value='{"title":"Vector Conversion", "price":4.99}'>Vector Conversion</option> */} </Form.Control>
                                    </Form.Group>
                                    <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                        <Form.Label>Enter quantity*</Form.Label>
                                        <Form.Control type="number" placeholder="1"
                                            onChange={
                                                (e) => {
                                                    this.quentityPrice(e)
                                                }
                                            }/>
                                    </Form.Group>

                            </Col>
                            <Col lg="6 px-5">

                                {
                                this.state.before ? (

                                    <Row className="pricingcontent mb-4">
                                        <Col lg="6 border-right">
                                            <Image src={
                                                    '/img/clippingpath/' + this.state.before
                                                }
                                                fluid/>
                                        </Col>
                                        <Col lg="6">
                                            <Image src={
                                                    '/img/clippingpath/' + this.state.after
                                                }
                                                fluid/>
                                        </Col>
                                    </Row>
                                ) : (
                                    <h3 className="text-danger">Please select an service</h3>
                                )
                            }

                                <Form.Group as={Row}
                                    controlId="">
                                    <Form.Label column sm="2">
                                        <b>
                                            Service</b>
                                    </Form.Label>
                                    <Col sm="10 pt-2">
                                        <span>{
                                            this.state.title
                                        }</span>
                                    </Col>
                                </Form.Group>
                                <Form.Group as={Row}
                                    controlId="">
                                    <Form.Label column sm="2">
                                        <b>Quentity
                                        </b>
                                    </Form.Label>
                                    <Col sm="10 pt-2">
                                        <span>{
                                            this.state.quentity
                                        } </span>
                                        Images
                                    </Col>
                                </Form.Group>
                                <h3 className="text-info">${
                                    this.state.minPrice.toFixed(2)
                                }
                                    - {
                                    this.state.maxPrice.toFixed(2)
                                }
                                    USD</h3>
                            </Col>
                            <Col lg="12 d-flex justify-content-md-center py-4">
                                <Link className="btn btn-warning"  to="/free-trial">Complete my quote</Link>
                            </Col>

                        </Row>
                    </Form>


                </Row>

            </Container>

            <Footer/>
        </React.Fragment>

        )
    }
}
