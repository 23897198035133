import React, { Component }
    from 'react';
import { Nav, Navbar, Image, } from 'react-bootstrap';
export default class NavPage extends Component {
    render() {
        return (
            <Navbar expand="lg" className="col-lg-12 ">
                <Navbar.Brand href="/"><Image src="./img/logo.webp" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav >
                        <Nav.Link href="/">Home</Nav.Link>
                        <Nav.Link href="/#services">Services</Nav.Link>
                        <Nav.Link href="/free-trial">Free Trial</Nav.Link>
                        <Nav.Link href="/pricing">Pricing</Nav.Link>
                        <Nav.Link href="/quotations">Get quote</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }



}