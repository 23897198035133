import React, {Component} from 'react';
import {
    Card,
    Form,
    Table,
    InputGroup,
    FormControl,
    Row,
    Col,
    Container,
    Alert,
    Image,
    Button
} from 'react-bootstrap';

import NavPage from '../Components/Nav';


import Footer from '../Components/Footer';

import {URL} from '../constants';
import Pace from 'react-pace-progress';
import Recaptcha from 'react-recaptcha';

export default class Quotations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            serviceId: 0,
            isLoading: false,
            isVerify: false

        };
        this.handleSubmission = this.handleSubmission.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

    }

    callback() {
        console.log('Done!!!!');
    };


    verifyCallback(response) {

        this.setState({isVerify: true});

        // console.log(response);
    };

    pricingOptions = (e) => {

        var localServices = localStorage.services && JSON.parse(localStorage.getItem('services'));
        var html = [];
        if (localStorage.services) {


            localServices.map(service => {


                html.push (
                    <Form.Check value={
                            service.id
                        }
                        name="services[]"
                        type="checkbox"
                        label={
                            service.name
                        }/>
                );
            })


        }

        return html;


    }
    handleSubmission = (event) => { // var formBody = 'status=1&InsertedId='+this.state.InsertedId;
        event.preventDefault();
        this.setState({isLoading: true});


        const form = event.target;
        const data = new FormData(form);


        fetch(URL + '/api/quotations', {
            method: 'POST',
            body: data

        }).then(response => response.json()).then(response => {
            this.setState({redirectToReferrer: true, isLoading: false});


        }).catch((err) => {
            alert('Something is error   ! ');
        });
    }

    render() {
        if (this.state.redirectToReferrer) {

            setTimeout(function () {
                window.location.reload();
            }, 2000);


        }
        return (
            <React.Fragment>
                <Container fluid className="bg-gray">
                    <Row>
                        <Container>
                            <Row>
                                <NavPage/>
                            </Row>
                        </Container>

                    </Row>

                </Container>
                <Container>
                    <Row className="d-flex justify-content-center">
                        <h3 className="py-5 small-border-bottom">GET YOUR QUOTE IN 30 MINUTES
                        </h3>
                        <p>Fill out the form bellow with details of your image-editing needs, and we'll eamil your quote within 30 minutes</p>
                    </Row>

                </Container>

                <Container className="mb-3">
                    <Row>

                        <Form className="container"
                            onSubmit={
                                this.handleSubmission
                        }>
                              <Col lg="12">
                                {
                                this.state.redirectToReferrer && <Alert variant={'success'}>
                                    Your request has been submited ! We will contact you soon.
                                </Alert>
                            } </Col>
                            <Row className="pricingcontent py-5">

                                <Col lg="12 px-5 ">

                                    <Row>
                                        <Col lg="6">
                                            <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                                <Form.Label>First Name*</Form.Label>
                                                <Form.Control name="fname" type="text" placeholder="" required/>
                                            </Form.Group>
                                        </Col>
                                        <Col lg="6">
                                            <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                                <Form.Label>Last Name*</Form.Label>
                                                <Form.Control name="lname" type="text" placeholder="" required/>
                                            </Form.Group>

                                        </Col>
                                    </Row>


                                    <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                        <Form.Label>E-mail*</Form.Label>
                                        <Form.Control type="text" placeholder="" name="email" required/>
                                    </Form.Group>


                                    <Form.Group as={Row}>

                                        <Col sm="6">
                                            {
                                            this.pricingOptions()
                                        } </Col>

                                    </Form.Group>
                                    <Row>
                                        <Col lg="6">
                                            <Form.Group className="py-2">
                                                <Form.Label>
                                                    How many images do you need a quote for?*
                                                </Form.Label>
                                                <Form.Control name="quentity" type="number" required/>
                                            </Form.Group>

                                        </Col>
                                        <Col lg="6">
                                            <Form.Group className="py-2">
                                                <Form.Label>
                                                    Do you need your images resized?*
                                                </Form.Label>
                                                <Form.Check name="resize" value="1" type="radio" label="Yes"/>
                                                <Form.Check name="resize" value="0" type="radio" label="No"/>
                                            </Form.Group>

                                        </Col>

                                    </Row>
                                    <Form.Group className="py-2">

                                        <Form.Check name="straighten" value="1" type="checkbox" label="Straighten, crop and set margin (FREE) "/>
                                    </Form.Group>


                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>
                                            Additional comments (optional)</Form.Label>
                                        <Form.Control name="comments" as="textarea" rows="3"/>
                                    </Form.Group>

                                </Col>
                                <Col lg="12" className="d-flex justify-content-center ">
                                    <Recaptcha sitekey="6Ldscs8ZAAAAAOAMFubUCo1nXWU1zlml8ol9PNfi"

                                        verifyCallback={
                                            this.verifyCallback
                                        }
                                        onloadCallback={
                                            this.callback
                                        }/>
                                </Col>

                                <Col lg="12 d-flex justify-content-md-center py-4">
                                    <Button variant="warning" type="submit" size="md">Complete my quote</Button>
                                </Col>

                            </Row>
                        </Form>


                    </Row>
                </Container>

                <Footer/>
            </React.Fragment>

        )
    }
}
