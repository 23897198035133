import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import './App.css';


import HomePage from './Home/HomePage';
import Pricing from './Pricing/Pricing';
import FreeTrail from './FreeTrail/FreeTrail';

import Quotations from './Quotations/Quotations';


function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route exact path="/pricing" component={Pricing} />
        <Route exact path="/free-trial" component={FreeTrail} />
        <Route exact path="/quotations" component={Quotations} />
        
      </Switch>
    </Router>
  );
}

export default App;
