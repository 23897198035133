import React, { Component }
    from 'react';

import { ResponsiveEmbed, Nav, Row, Navbar, Col, Container, Image, Button, ButtonToolbar } from 'react-bootstrap';
import NavPage from '../Components/Nav';

export default class headerHomePage extends Component {
    render() {
        return (
            <Container fluid={true} className="headerHomePage">
                <Row>
                    <Container  >
                        <Row className="mb-4">
                            <Container>
                                <Row>
                                    <NavPage />
                                </Row>

                            </Container>




                        </Row>
                        <Row className="pt-5">


                            <Col lg="6" xs="12">
                                <Row>
                                    <Col lg="12">
                                        <h3 className="textBload">CENT<span className="textGreen"> CLIPPING PATH</span></h3>

                                    </Col>

                                </Row>
                                {/* <Row>
                                    <Col lg="12">
                                        <h2 className="textBlue textBload">Revolation photo editting service</h2>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col lg="9 py-3">
                                        <p>Hand-drawn clipping path, <nobr>high-quality</nobr> image editing, quick turnaround time — all starting at just <span >$0.15 USD</span>/photo</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="12 d-block d-sm-none">
                                        <Image style={{ width: '500px' }} src="./img/header_illustrator.webp" fluid />

                                    </Col>


                                    <Col lg="12">
                                        <Row >
                                            <ButtonToolbar>
                                                <Button className="textMedium" variant="success" size="md">
                                                    FREE TRAIL
                                            </Button>
                                                <Button className="btn-blue textMedium" size="md">
                                                    PRiCING PLANS
                                            </Button>

                                            </ButtonToolbar>

                                        </Row>
                                    </Col>
                                </Row>

                            </Col>
                            <Col lg="6 d-none d-sm-block">
                                <Image src="./img/header_illustrator.webp" fluid />


                            </Col>
                        </Row>
                    </Container>
                </Row>
            </Container>
        )
    }


}