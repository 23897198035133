import React, {Component} from 'react';
import {
    Card,
    Form,
    Table,
    InputGroup,
    FormControl,
    Row,
    Col,
    Container,
    Image,
    Button,
    Alert
} from 'react-bootstrap';

import NavPage from '../Components/Nav';

import {URL} from '../constants';
import Footer from '../Components/Footer';
import Pace from 'react-pace-progress';
import Recaptcha from 'react-recaptcha';
export default class FreeTrail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirectToReferrer: false,
            serviceId: 0,
            isLoading: false,
            isVerify: false

        };
        this.handleSubmission = this.handleSubmission.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);

    }
    callback() {
        console.log('Done!!!!');
    };


    verifyCallback(response) {

        this.setState({isVerify: true});

        // console.log(response);
    };

    pricingOptions = (e) => {

        var localServices = localStorage.services && JSON.parse(localStorage.getItem('services'));
        var html = [];
        if (localStorage.services) {


            localServices.map(service => {

                var tempData = {
                    "id": service.id,
                    "title": service.name,
                    "min": service.min,
                    "max": service.max,
                    "before": service.before_img,
                    "after": service.after_img
                };

                html.push (
                    <option value={
                        JSON.stringify(tempData)
                    }>
                        {
                        service.name
                    }</option>
                );
            })


        }

        return html;


    }
    handleSubmission = (event) => { // var formBody = 'status=1&InsertedId='+this.state.InsertedId;
        event.preventDefault();
        this.setState({isLoading: true});


        const form = event.target;
        const data = new FormData(form);

        data.append('request_type', 1);
        data.append('service', this.state.serviceId);


        fetch(URL + '/api/freetrail', {
            method: 'POST',
            body: data

        }).then(response => response.json()).then(response => {
            this.setState({redirectToReferrer: true, isLoading: false});


        }).catch((err) => {
            alert('Something is error   ! ');
        });
    }
    handleChange = (e) => {

        var data = JSON.parse(e.target.value);

        this.setState({serviceId: data.id});

    }


    render() {

        if (this.state.redirectToReferrer) {

            setTimeout(function () {
                window.location.reload();
            }, 2000);


        }

        return (
            <React.Fragment> {
                this.state.isLoading ? <Pace color="red"/> : null
            }
                <Container fluid className="bg-gray">
                    <Row>
                        <Container>
                            <Row>
                                <NavPage/>
                            </Row>
                        </Container>

                    </Row>

                </Container>
                <Container>
                    <Row className="d-flex justify-content-center">
                        <h3 className="py-5 small-border-bottom">Send Free Trial</h3>
                        <p>We Want show our working quality for that reason we doing 2 images for free.If you have any question about confidentiality? Don’t worry, your images are 100% safe with us.</p>
                    </Row>

                </Container>

                <Container className="mb-3">

                    <Form onSubmit={
                        this.handleSubmission
                    }>
                        <Row className="pricingcontent py-5">
                            <Col lg="12">
                                {
                                this.state.redirectToReferrer && <Alert variant={'success'}>
                                    Your request has been submited ! We will contact you soon.
                                </Alert>
                            } </Col>


                            <Col lg="6 px-5 ">

                                <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name*</Form.Label>
                                    <Form.Control name="name" type="text" placeholder="" required/>
                                </Form.Group>
                                <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>E-mail*</Form.Label>
                                    <Form.Control type="text" name="email" placeholder="" required/>
                                </Form.Group>


                                <Form.Group className="py-2" controlId="exampleForm.ControlSelect1">
                                    <Form.Label>Select a service*</Form.Label>
                                    <Form.Control as="select"
                                        onChange={
                                            (e) => this.handleChange(e)
                                        }
                                        required>

                                        <option data-value="">Select a Service</option>
                                        {
                                        this.pricingOptions()
                                    } </Form.Control>
                                </Form.Group>
                                <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Files</Form.Label>
                                    <div class="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupFileAddon01">Upload</span>
                                        </div>
                                        <div className="custom-file">
                                            <input name="files" type="file" accept="image/*" className="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01" required/>
                                            <label className="custom-file-label" for="inputGroupFile01">Choose file</label>
                                        </div>
                                    </div>

                                </Form.Group>

                            </Col>
                            <Col lg="6 px-5">
                                <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Company</Form.Label>
                                    <Form.Control name="company" type="text" placeholder=""/>
                                </Form.Group>
                                <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>
                                        Address
                                    </Form.Label>
                                    <Form.Control name="address" type="text" placeholder=""/>
                                </Form.Group>
                                <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>
                                        Telephone
                                    </Form.Label>
                                    <Form.Control name="telephone" type="text" placeholder="+1000000000000"/>
                                </Form.Group>
                                <Form.Group className="py-2" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Website
                                    </Form.Label>
                                    <Form.Control name="website" type="text" placeholder="www.example.com"/>
                                </Form.Group>

                            </Col>
                            <Col lg="12" className="d-flex justify-content-center ">
                                <Recaptcha sitekey="6Ldscs8ZAAAAAOAMFubUCo1nXWU1zlml8ol9PNfi"

                                    verifyCallback={
                                        this.verifyCallback
                                    }
                                    onloadCallback={
                                        this.callback
                                    }/>
                            </Col>
                            <Col lg="12 d-flex justify-content-md-center py-4">
                                <Button type="submit" variant="warning" size="md">Complete my quote</Button>
                            </Col>

                        </Row>
                    </Form>


                </Container>

                <Footer/>
            </React.Fragment>

        )
    }
}
