import React, { Component } from 'react';
import { Container, Button, Accordion, Tab, Nav, Image, Row, Col, CardGroup, Card } from 'react-bootstrap';

export default class Footer extends Component {
    render() {
        return (
            <React.Fragment>
                <Container className="footer" fluid={true}>
                    <Container>
                        <Row>
                            <Col lg="4" xs="12">
                                <p className="footer-text"> <span>Cent Clipping Path </span>
                            Is one of the largest photo editting company wich is serving more than 10 years with 500+ happy clients and 10M+ photo editting .
                            </p>
                            </Col>
                            <Col xs="12" lg="4 d-flex justify-content-center">
                                <Row className="firstlink">
                                    <Col lg="12 ">
                                        <h4>FAST LINK</h4>

                                        <ul>
                                            <li> <a href="#">Services </a></li>
                                            <li><a href="#">Pricing Panel</a></li>
                                            <li><a href="#">Start your free Trail</a></li>
                                            <li><a href="#">Get  quote ?</a></li>
                                        </ul>
                                    </Col>

                                </Row>


                            </Col>
                            <Col xs="12" lg="4" className="subscribeus">
                                <h4 >SUBCRIBLE US</h4>

                                <input placeholder="Enter your email here" type="email" className="subscribeInput" />

                                <Button className="textMedium subscribeusbtn my-4" variant="success" size="md">
                                    SEND NOW
                            </Button>
                            </Col>
                        </Row>
                    </Container>




                </Container>
                <Container fluid={true} className="bottomfooter">

                    <Container>
                        <Row>
                            <Col lg="4">
                                <p className="py-4">Copyright 2020 - <span>ALL RIGHT RESEVERED</span></p>
                            </Col>
                            <Col lg="4">
                                <p className="py-4">E-mail :-  contact@centclippingpath.com</p>

                            </Col>
                            <Col lg="4">
                                <p className="py-4">Phone : +1 6012875223‬ </p>

                            </Col>

                        </Row>


                    </Container>

                </Container>
            </React.Fragment>
        )
    }


}